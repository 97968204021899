import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { debounceTime, map, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { ScrollEndDirective } from 'src/directives/scroll-end.directive';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';

@Component({
  selector: 'app-split-page',
  templateUrl: './split-page.component.html',
  styleUrls: ['./split-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SplitPageComponent implements AfterViewInit {
  private init$ = new Subject<void>();

  private stop$ = new Subject<void>();

  // A template reference is required for the template outlet to work.
  //
  @ContentChild('left') leftTemplate: TemplateRef<any>;

  @ContentChild('right') rightTemplate: TemplateRef<any>;

  // A template reference is required for the template outlet to work.
  //
  @Input() leftLabel: string;

  @Input() rightLabel: string;

  @Input() hide: boolean;

  @Input() whatToHide: 'tab' | 'side' | 'all' = 'all';

  @Input() sideToHide: 'left' | 'right' = 'left';

  @Input() activeTab = 0;

  @Input() leftWidth = 50;

  @Input() scrollEventsLeft = false;

  @Input() scrollEventsRight = false;

  @Input() parentSelectorToListenForLeftScroll?: string;

  @Input() parentSelectorToListenForRightScroll?: string;

  @Output()
    selectedTabChange: EventEmitter<MatTabChangeEvent> = new EventEmitter<MatTabChangeEvent>();

  @Output()
    scrollEndLeft: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  @Output()
    scrollEndRight: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  @Output() scrollTopRight: EventEmitter<number> = new EventEmitter<number>();

  @Output() scroll: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  @ViewChild(ScrollEndDirective) scrollEnd: ScrollEndDirective;

  @ViewChild('rightTab') rightPanel: ElementRef;

  @ViewChild('leftTab') leftPanel: ElementRef;

  private get hideSide(): boolean {
    return (
      this.hide && (this.whatToHide === 'side' || this.whatToHide === 'all')
    );
  }

  private get hideTab(): boolean {
    return (
      this.hide && (this.whatToHide === 'tab' || this.whatToHide === 'all')
    );
  }

  private get hideRight(): boolean {
    return this.sideToHide === 'right';
  }

  private get hideLeft(): boolean {
    return this.sideToHide === 'left';
  }

  public get hideLeftSide(): boolean {
    return this.hideSide && this.hideLeft;
  }

  public get hideRightSide(): boolean {
    return this.hideSide && this.hideRight;
  }

  public get hideLeftTab(): boolean {
    return this.hideTab && this.hideLeft;
  }

  public get hideRightTab(): boolean {
    return this.hideTab && this.hideRight;
  }

  public isSmall$: Observable<boolean>;

  public isMobile$: Observable<boolean>;

  public showTabLayout$: Observable<boolean>;

  private rightPanelIsFilled$ = new Subject<boolean>();

  private leftPanelIsFilled$ = new Subject<boolean>();

  private observers: MutationObserver[] = [];

  constructor(private $breakpoint: BreakpointsProvider, private breakpointObserver: BreakpointObserver) {
    this.isSmall$ = this.$breakpoint.isSmall$;
    this.isMobile$ = this.$breakpoint.isMobile$;

    this.showTabLayout$ = this.$breakpoint.isSmall$.pipe(
      withLatestFrom(
        this.breakpointObserver
          // This number should catch the problematically small window sizes for tablets
          // while being below the usual window size for laptops and desktops.
          .observe(['(max-width: 1024px)'])
          .pipe(
            map(result => result.matches),
          ),
      ),
      map(([isSmall, isMobile]) => isSmall || isMobile),
    );
  }

  ngOnInit() {
    this.rightPanelIsFilled$.pipe(takeUntil(this.stop$), debounceTime(300)).subscribe((isFilled) => {
      if (!isFilled && this.scrollEventsRight) {
        this.scrollEndReachedRight(this.rightPanel);
      }
    });

    this.leftPanelIsFilled$.pipe(takeUntil(this.stop$), debounceTime(300)).subscribe((isFilled) => {
      if (!isFilled && this.scrollEventsLeft) {
        this.scrollEndReachedLeft(this.leftPanel);
      }
    });
  }

  ngAfterViewInit() {
    this.init$.next();

    const rightPanelObserver = new MutationObserver(() => {
      if (this.rightPanel?.nativeElement) {
        const castedNativeElement =  (this.rightPanel.nativeElement as Element);
        const isFullyFilled = this.determineIfElementIsFullyFilled(castedNativeElement);

        this.rightPanelIsFilled$.next(isFullyFilled);
      }
    });

    // define what element should be observed by the observer
    // and what types of mutations trigger the callback
    rightPanelObserver.observe(this.rightPanel?.nativeElement, {
      subtree: true,
      childList: true,
      attributes: true,
    });

    const leftPanelObserver = new MutationObserver(() => {
      if (this.leftPanel?.nativeElement) {
        const castedNativeElement =  (this.leftPanel.nativeElement as Element);
        const isFullyFilled = this.determineIfElementIsFullyFilled(castedNativeElement);

        this.leftPanelIsFilled$.next(isFullyFilled);
      }
    });

    // define what element should be observed by the observer
    // and what types of mutations trigger the callback
    leftPanelObserver.observe(this.leftPanel?.nativeElement, {
      subtree: true,
      childList: true,
      attributes: true,
    });

    if (this.observers.length > 0) {
      this.observers.forEach((observer) => observer.disconnect());
    }
    this.observers = [leftPanelObserver, rightPanelObserver];
  }

  ngOnDestroy() {
    this.stop$.next();
    this.observers.forEach((observer) => observer.disconnect());
  }

  private determineIfElementIsFullyFilled(element: Element) {
    const elementHeight = element.clientHeight;
    let totalChildrenHeight = 0;

    for (let i = 0; i < element.children.length; i += 1) {
      const childNode = element.children.item(i);
      totalChildrenHeight += childNode.clientHeight;
    }


    return elementHeight <= totalChildrenHeight;
  }

  public selectedTabChangeEvent(event: MatTabChangeEvent) {
    this.selectedTabChange.emit(event);
  }

  scrollEndReachedLeft(reference) {
    this.scrollEndRight.emit(reference);
  }

  scrollEndReachedRight(reference) {
    this.scrollEndRight.emit(reference);
  }

  scrollTopUpdatedRight(scrollTop) {
    this.scrollTopRight.emit(scrollTop);
  }

  async scrollRight(scrollTop: number) {
    if (this.scrollEnd) {
      this.scrollEnd.scroll(scrollTop);
    }
  }

  onScroll($event: Event) {
    this.scroll.emit($event.target as any);
  }
}
