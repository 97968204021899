<app-doenkids-menu-bar>
  <div left>
    <div class="search">
      <mat-icon>search</mat-icon>
      <input matInput [placeholder]="'activity_overview.search.placeholder' | translate" [formControl]="searchControl" (keydown.enter)="advancedSearch()" />

      <button class="extended-search-button" mat-button (click)="advancedSearch()">
        {{ 'activity_overview.search.label' | translate }}
        <mat-icon>arrow_right</mat-icon>
      </button>
    </div>
  </div>
</app-doenkids-menu-bar>

<div class="page-container" #pageContainer>
  <div class="activities-for-review">
    <ng-container *ngIf="(isAdmin$ | async) || (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async)">
      <ng-container *ngIf="countNumber$ | async as countValue">
        <a (click)="goToActivitiesToReview()" *ngIf="countValue > 0" mat-flat-button>{{
          'activity.review.count' | translate : { countValue }
        }}</a>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngFor="let activitySection of activitySections$ | async">
    <ng-container *ngIf="!activitySection.isOptional || activitySection.activities.length > 0">
      <h2 class="row-title default-cursor">
        {{ activitySection.translationKey | translate }}
      </h2>
      <div [ngClass]="['activity-row', activitySection.name]">
        <swiper-container
          *ngIf="activitySection.activities.length > 0; else noResults"
          class="swiper-container"
          fxFlex="auto"
          dkSwiper
          [config]="swiperConfig"
          init="false"
        >
          <swiper-slide
            *ngFor="let activity of activitySection.activities; let i = index"
            [ngClass]="['zoom-box', activitySection.name === 'top-10' ? 'top-10-item' : 'activity-item']"
            [class.first-number]="activitySection.name === 'top-10' && i === 0"
            [class.double-digit-number]="activitySection.name === 'top-10' && i >= 9"
          >
            <img
              *ngIf="activitySection.name === 'top-10'"
              class="number"
              [class.first-number]="i === 0"
              [class.double-digit-number]="i >= 9"
              src="/assets/images/top-10-numbers/{{ i + 1 }}.svg"
            />

            <div class="activity" (click)="goToActivityPreview(activity)">
              <app-doenkids-image [mediaItem]="{ uuid: activity.media_uuid, description: '' }" [preview]="true" previewVariant="medium"></app-doenkids-image>
              <div class="content">
                <h3 class="title">{{ activity.name }}</h3>
                <span class="subtitle">{{ activity.subtitle }}</span>
              </div>
            </div>
          </swiper-slide>

          <swiper-slide
            *ngIf="activitySection.isSearchable"
            class="see-more-card"
            (click)="activityKindSearch(activitySection.activityKindName)"
          >
            <span>
              {{
                'activity_overview.see_more'
                  | translate
                    : {
                        activityKindName:
                          activitySection.name === 'all'
                            ? undefined
                            : (activitySection.translationKey + activitySection.seeMoreTranslationKeySuffix | translate | lowercase)
                      }
              }}
            </span>
          </swiper-slide>
        </swiper-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #noResults>
  <div *ngIf="loaded$ | async" matTooltipPosition="right" [matTooltip]="'activity_overview.no_results.tooltip' | translate" class="activity not-found">
    <img [src]="activity404Image" [alt]="'activity_overview.no_results.alt' | translate" />
  </div>
  <div *ngIf="!(loaded$ | async)" [matTooltip]="'activity_overview.no_results.tooltip' | translate" class="activity not-found">
    <mat-spinner [diameter]="32" class="spinner"></mat-spinner>
  </div>
</ng-template>
